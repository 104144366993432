import React, { useState, useEffect } from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import { ReactTyped } from "react-typed";
import animationData from "./assets/Animation - 1726595563216.json";
import axios from "axios";
import loading from "./assets/LoadingAnimation.json";

const QuizApp = () => {
  // State variables
  const [showMessageWindow, setShowMessageWindow] = useState(true);
  const [showFirstInput, setShowFirstInput] = useState(false);
  const [showSecondInput, setShowSecondInput] = useState(false);
  const [showPuzzleButton, setShowPuzzleButton] = useState(false);
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [questionData, setQuestionData] = useState(null);
  const [currentScore, setCurrentScore] = useState(100);
  const [showChoices, setShowChoices] = useState(false);
  const [selectedChoice, setSelectedChoice] = useState(null);
  const [showScoreAndNext, setShowScoreAndNext] = useState(false);
  const [showFunFactModal, setShowFunFactModal] = useState(false);
  const [funFactLoaded, setFunFactLoaded] = useState(false);
  const [questionTypedComplete, setQuestionTypedComplete] = useState(false);
  const [choicesDelayOver, setChoicesDelayOver] = useState(false);

  // Input values and validation flags
  const [firstInputValue, setFirstInputValue] = useState("");
  const [secondInputValue, setSecondInputValue] = useState("");
  const [isFirstInputValid, setIsFirstInputValid] = useState(false);
  const [isSecondInputValid, setIsSecondInputValid] = useState(false);

  // Timers for initial inputs and buttons
  useEffect(() => {
    const firstInputTimer = setTimeout(() => {
      setShowFirstInput(true);
    }, 40000);

    const secondInputTimer = setTimeout(() => {
      setShowSecondInput(true);
    }, 41000); // 20s + 25s

    const puzzleButtonTimer = setTimeout(() => {
      setShowPuzzleButton(true);
    }, 42000); // 20s + 25s + 30s

    return () => {
      clearTimeout(firstInputTimer);
      clearTimeout(secondInputTimer);
      clearTimeout(puzzleButtonTimer);
    };
  }, []);

  // Functions to handle events
  const handlePuzzleButtonClick = () => {
    setShowLoadingModal(true);
    // Fetch question data
    axios
      .get(
        "https://node-complex-api.onrender.com/api/question?uuid=00001&quid=1000&score=100"
      )
      .then((response) => {
        setQuestionData(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleWelcomeButtonClick = () => {
    setShowLoadingModal(false);
    // Hide initial elements
    setShowMessageWindow(false);
    setShowFirstInput(false);
    setShowSecondInput(false);
    setShowPuzzleButton(false);
    // Show question window
    setShowChoices(true);
  };

  const handleChoiceClick = (index) => {
    if (selectedChoice !== null) return; // Prevent multiple selections
    setSelectedChoice(index);
    const scoreChange = questionData.scoreForChoice[index];
    setCurrentScore(currentScore + scoreChange);

    // Show score and next button after 30 seconds
    setTimeout(() => {
      setShowScoreAndNext(true);
    }, 1500);
  };

  const handleNextQuestionClick = () => {
    setShowFunFactModal(true);
    // Show "Loading..." under fun fact
    setFunFactLoaded(false);

    // Simulate API call delay
    setTimeout(() => {
      setFunFactLoaded(true);
    }, 2000);
  };

  const handleFunFactModalClose = () => {
    setShowFunFactModal(false);
    setShowChoices(false);
    setSelectedChoice(null);
    setShowScoreAndNext(false);
    setQuestionTypedComplete(false);
    setChoicesDelayOver(false);

    // Fetch the next question
    axios
      .get(
        `https://node-complex-api.onrender.com/api/question?uuid=00001&quid=${questionData.quid}&score=${currentScore}`
      )
      .then((response) => {
        setQuestionData(response.data);
        setShowChoices(true);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // Check inputs for specific text
  const checkFirstInput = (value) => {
    if (value.toLowerCase().includes("sherlock")) {
      setIsFirstInputValid(true);
      localStorage.setItem("firstInput", value);
    } else {
      setIsFirstInputValid(false);
    }
  };

  const checkSecondInput = (value) => {
    if (value.trim().toLowerCase() === "221b") {
      setIsSecondInputValid(true);
      localStorage.setItem("secondInput", value);
    } else {
      setIsSecondInputValid(false);
    }
  };

  // Handle input changes
  const handleFirstInputChange = (e) => {
    const value = e.target.value;
    setFirstInputValue(value);
    checkFirstInput(value);
  };

  const handleSecondInputChange = (e) => {
    const value = e.target.value;
    setSecondInputValue(value);
    checkSecondInput(value);
  };

  // Handle completion of question typing animation
  const handleQuestionTypedComplete = () => {
    setQuestionTypedComplete(true);
    // Add a 1-second delay before showing choices
    setTimeout(() => {
      setChoicesDelayOver(true);
    }, 1000);
  };

  return (
    <div className="min-h-screen bg-[#47a6e9] flex flex-col items-center">
      {/* Header */}
      <header className="w-full h-[15vh] flex items-center justify-center">
        <Player
          autoplay
          loop
          src={animationData}
          style={{ height: "60px", width: "60px" }}
        />
        <h1 className="text-white text-2xl ml-2">My Quiz</h1>
      </header>

      {/* Message Window */}
      {showMessageWindow && (
        <div className="font-special-elite w-11/12 bg-white p-4 rounded mt-4">
          <ReactTyped
            strings={[
              "Hi! Hope you are doing well.",
              "This is Ashwin. I'm creating a fun quiz with the help of GPT. I wrote the code for this website. If you see any spelling mistakes, then its me. The qustions and scoring is been done by GPT.",
              "Due to social media, we have stopped reading. Me and GPT are aiming to create fun content for people to read. If you didnt read this, it wont make sense; or will it? Once you fill the forms below. Click on 'What's the puzzle?'",
            ]}
            typeSpeed={60}
          />
        </div>
      )}

      {/* First Input */}
      {showFirstInput && (
        <div className="w-11/12 bg-white p-4 rounded mt-4">
          <label className="text-black">Who are you?</label>
          <input
            type="text"
            value={firstInputValue}
            onChange={handleFirstInputChange}
            className="w-full mt-2 p-2 border border-gray-300 rounded"
          />
          {!isFirstInputValid && firstInputValue && (
            <p className="text-red-500 mt-1">
              Hint: Think of a famous detective.
            </p>
          )}
        </div>
      )}

      {/* Second Input */}
      {showSecondInput && (
        <div className="w-11/12 bg-white p-4 rounded mt-4">
          <label className="text-black">Where am I?</label>
          <input
            type="text"
            value={secondInputValue}
            onChange={handleSecondInputChange}
            className="w-full mt-2 p-2 border border-gray-300 rounded"
          />
          {!isSecondInputValid && secondInputValue && (
            <p className="text-red-500 mt-1">
              Hint: It's a famous address in London.
            </p>
          )}
        </div>
      )}

      {/* Puzzle Button */}
      {showPuzzleButton && (
        <button
          onClick={handlePuzzleButtonClick}
          className="mt-4 bg-[#47a6e9] text-white px-4 py-2 rounded"
          disabled={!(isFirstInputValid && isSecondInputValid)}
        >
          What's the puzzle?
        </button>
      )}

      {/* Loading Modal */}
      {showLoadingModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-gray-100 p-4 rounded w-11/12">
            <p className="text-black">
              <ReactTyped
                strings={[
                  "Running servers on the web is an expensive affair. Specially, AI compute. To save money, we put them servers to sleep",
                  "It takes some time to load, If its taking more than a minute; I would suggest you com back in 5 mins",
                  "If its taking even longer; Refresh",
                ]}
                typeSpeed={70}
              />
            </p>
            {questionData ? (
              <button
                onClick={handleWelcomeButtonClick}
                className="mt-4 bg-[#47a6e9] text-white px-4 py-2 rounded"
              >
                Server ready!
              </button>
            ) : (
              <p className="text-black mt-2">
                <Player
                  autoplay
                  loop
                  src={loading}
                  style={{ height: "60px", width: "60px" }}
                />
              </p>
            )}
          </div>
        </div>
      )}

      {/* Question Window */}
      {showChoices && questionData && (
        <div className="w-11/12 bg-white p-4 rounded mt-4">
          <ReactTyped
            strings={[questionData.question]}
            typeSpeed={40}
            onComplete={handleQuestionTypedComplete}
          />
          {/* Choices */}
          {questionTypedComplete && choicesDelayOver && (
            <div className="mt-4">
              {questionData.choice.map((choiceText, index) => (
                <button
                  key={index}
                  onClick={() => handleChoiceClick(index)}
                  className={`w-full mb-2 px-4 py-2 rounded text-white ${
                    selectedChoice === index
                      ? questionData.scoreForChoice[index] > 0
                        ? "bg-[#28a745]"
                        : "bg-[#dc3545]"
                      : "bg-[#47a6e9]"
                  }`}
                  disabled={selectedChoice !== null}
                >
                  {choiceText}
                </button>
              ))}
            </div>
          )}
        </div>
      )}

      {/* Score and Next Question */}
      {showScoreAndNext && (
        <div className="flex items-center mt-4">
          <p className="text-white">Score: {currentScore}</p>
          <button
            onClick={handleNextQuestionClick}
            className="ml-4 bg-[#47a6e9] text-white px-4 py-2 rounded"
          >
            Next Question
          </button>
        </div>
      )}

      {/* Fun Fact Modal */}
      {showFunFactModal && questionData && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div
            className="bg-white p-4 rounded w-11/12 relative"
            onClick={funFactLoaded ? handleFunFactModalClose : null}
          >
            <ReactTyped
              strings={[questionData.funfact]}
              typeSpeed={40}
              onComplete={() => setFunFactLoaded(true)}
            />
            {!funFactLoaded ? (
              <p className="text-black mt-2">Loading...</p>
            ) : (
              <p className="text-black mt-2">Tap anywhere to close</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default QuizApp;
